<script setup>
import SectionHeader from "@/Components/SectionHeader.vue";
import PostCard from "@/Components/PostCard.vue";
import { onMounted } from "vue";
import { Head, Link } from "@inertiajs/vue3";
import Button3 from "@/Components/Button3.vue";
onMounted(() => {});

defineProps({
  projects: Object,
});
</script>
<template>
  <section id="portfolio" class="py-5">
    <div class="container">
      <SectionHeader
        :selection-name="$t('home.portfolio.title')"
        :description="$t('home.portfolio.desc')"
      />
      <div class="pb-5">
        <div class="row justify-content-center align-items-center gap-4 pt-4">
          <PostCard
            v-for="project in projects.data"
            :key="project.id"
            class="col-10 col-md-5 col-xl-3"
            :project="project"
          />
        </div>
        <Link
          v-if="projects.length > 6"
          :href="route('project.index')"
          class="d-flex align-items-center justify-content-center w-100"
        >
          <Button3 class="mt-5 col-3 p-2 text-capitalize">
            {{ $t("home.portfolio.btn") }}
          </Button3>
        </Link>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped></style>
